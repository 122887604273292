const Resume = () => {
  const professionalSkillsFirst = [
    {
      id: 1,
      name: "Web Design",
      value: 50,
    },
    {
      id: 2,
      name: "Logo Design",
      value: 50,
    },
    {
      id: 3,
      name: "Graphic Design",
      value: 50,
    },
    {
      id: 4,
      name: "Laravel",
      value: 65,
    },
    {
      id: 5,
      name: "Bootstrap",
      value: 75,
    },
    {
      id: 6,
      name: "JavaScript",
      value: 75,
    },
    {
      id: 7,
      name: "PHP",
      value: 60,
    },
    {
      id: 7,
      name: "Typescript",
      value: 20,
    },
  ];
  const professionalSkillsSecond = [
    {
      id: 1,
      name: "Python",
      value: 25,
    },
    {
      id: 2,
      name: "Umbraco",
      value: 40,
    },
    {
      id: 3,
      name: "HTML / CSS",
      value: 99,
    },
    {
      id: 4,
      name: "C#",
      value: 25,
    },
    {
      id: 5,
      name: "ReactJS",
      value: 40,
    },
    {
      id: 6,
      name: "React-native",
      value: 25,
    },
    {
      id: 7,
      name: "Flutter",
      value: 20,
    },
  ];

  const renderListOfProfessionalSkills = (skills) => {
    return skills.map((item) => (
      <div id={item.id} className="single-skill" data-percentage={item.value}>
        {/* <div className="skill-info">
          <span className="skill-name">{item.name}</span>
          <span className="skill-percentage" />
        </div> */}
        <div id={item.id} className="single-skill">
          <div className="skill-info">
            <span className="skill-name">{item.name}</span>
            <span style={{ color: "#009e66", fontSize: "20px" }}>
              <i className="icon ion-md-checkmark-circle-outline" />
            </span>
          </div>
        </div>
        {/* <div className="progress skill-progress">
          <div
            className="progress-bar"
            role="progressbar"
            aria-valuemin={0}
            aria-valuemax={100}
          />
        </div> */}
      </div>
    ));
  };
  return (
    <>
      <div className="container page-top-margin-medium page-bottom-margin">
        {/* <div className="lightbox-close">
                    <div className="close-btn" data-modal-close><span className="btn-line" /></div>
                </div> */}
        <div className="row">
          <div className="col-12">
            <div>
              <div className="row">
                <div className="col-12">
                  <div className="section-heading page-heading">
                    {/* <p className="section-description">Check out my Resume</p> */}
                    <h2 className="section-title text-center p-4">Resume</h2>
                    <div className="animated-bar" />
                  </div>
                </div>
              </div>
              {/* Resume section*/}
              <div className="resume-section single-section">
                <div className="row">
                  {/* Education part*/}
                  {/* <div className="col-12 col-md-6">
                                        <div className="col-block education">
                                            <h3 className="col-title">Education</h3>
                                            <div className="resume-item"><span className="item-arrow" />
                                                <a href="#" target="_blank" rel="noreferrer" >
                                                    <h5 className="item-title">Bachelor of Science</h5>
                                                </a>
                                                <span className="item-details">Hogeschool Utrecht / 2019 - 2024</span>
                                                <p className="item-description">In 2019 they started with a new program: Open-ICT. Open-ICT gives you a lot of freedom to decide what you want to learn. However, you must be able to handle this freedom. Open-ICT therefore fits particularly well with me because I like to study independently, want to work together with others, have a high ambition and self-discipline.</p>
                                            </div>
                                            <div className="resume-item"><span className="item-arrow" />
                                                <a href="#" target="_blank" rel="noreferrer" >
                                                    <h5 className="item-title">Software Developer</h5>
                                                </a>
                                                <span className="item-details">Natin - MBO / 2010 - 2014</span>
                                                <p className="item-description">At NATIN-MBO I learned the fundamentals of programming, designing systems using the Unified Modeling Language, creating databases and developing in C++ and Java.</p>
                                            </div>
                                            <div className="resume-item"><span className="item-arrow" />
                                                <a href="#" target="_blank" rel="noreferrer" >
                                                    <h5 className="item-title">A+ PC technician</h5>
                                                </a>
                                                <span className="item-details">Hogeschool Utrecht / 2011 - 2012</span>
                                                <p className="item-description">This was a training of six months where I learned to install and configure operating systems, assemble hardware of a computer and laptop, troubleshoot and fix software and hardware problems.</p>
                                            </div>
                                            <div className="resume-item"><span className="item-arrow" />
                                                <a href="#" target="_blank" rel="noreferrer" >
                                                    <h5 className="item-title">Electrical Engineering</h5>
                                                </a>
                                                <span className="item-details">Suriname Technical School 2 / 2006 - 2010</span>
                                                <p className="item-description">STS stands for Suriname Technical School, a school where I learned the basics of electrical engineering.</p>
                                            </div>
                                        </div>
                                    </div> */}
                  {/* Experience part*/}
                  <div className="col-12 col-md-6">
                    <div className="col-block experience">
                      <h3 className="col-title">Experience NL</h3>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <h5 className="item-title">
                          Software Developer / Internship
                        </h5>
                        <span className="item-details">
                          Experience Fruit Quality / Nov 2022 - Jul 2024
                        </span>
                        <p className="item-description">
                          As a software developer I am creating dynamic
                          prototypes that bring ideas to life with interactive
                          and user-friendly designs made in Figma. I not only
                          conceptualize and design these prototypes but also
                          implement the designs, ensuring seamless integration
                          with the platform.
                        </p>
                      </div>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <h5 className="item-title">
                          Frontend Developer / Internship
                        </h5>
                        <span className="item-details">
                          Experience Data / Nov 2022 - Jul 2024
                        </span>
                        <p className="item-description">
                          For Experience data I am involved in maintaining their
                          projects design in Figma and making dynamic
                          prototypes.
                        </p>
                      </div>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <h5 className="item-title">
                          Junior Frontend Developer
                        </h5>
                        <span className="item-details">
                          Valsecco / Feb 2021 - Feb 2022
                        </span>
                        <p className="item-description">
                          At Valsecco my tasks were mostly to implement the MS
                          Fluent UI into their web application.
                        </p>
                      </div>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <h5 className="item-title">
                          Frontend developer / Internship
                        </h5>
                        <span className="item-details">
                          Afas software / Sep 2020 - Feb 2021
                        </span>
                        <p className="item-description">
                          For the content team of Afas Software I worked on a
                          software tool that retrieves real-time data from their
                          main platform (Afas software package). In this tool I
                          worked on the front-end UI/UX. We called it Rocket.
                        </p>
                      </div>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <h5 className="item-title">Software Developer</h5>
                        <span className="item-details">
                          Vergelijkgroep / Jun 2020 - Sep 2020
                        </span>
                        <p className="item-description">
                          As software developer at Vergelijkgroep I was
                          responsible for developing and implementing new
                          features for the marketing department.
                        </p>
                      </div>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <h5 className="item-title">Freelance Web Developer</h5>
                        <span className="item-details">
                          Freelancer / Nov 2019 - April 2020
                        </span>
                        <p className="item-description">
                          As freelance developer I created WordPress websites
                          integrated with the WooCommerce plugin to establish
                          robust and efficient webshops. My responsibilities
                          included adding comprehensive content to the online
                          stores and setting up Stripe as the payment method to
                          ensure secure and seamless transactions. Additionally,
                          I managed the domain names and selected the
                          appropriate hosting services to optimize the
                          performance and reliability of the webshops..
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="col-block experience">
                      <h3 className="col-title">Experience SR</h3>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <h5 className="item-title">Software Developer</h5>
                        <span className="item-details">
                          ADEPT NV / Mar 2018 - Jun 2019
                        </span>
                        <p className="item-description">
                          As software developer at ADEPT I was responsible for
                          developing and implementing new software programs,
                          maintenance and performance improvement of existing
                          software.
                        </p>
                      </div>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <h5 className="item-title">
                          HACKOMATION INTERNET OF THINGS PROTOTYPING CHALLENGE
                        </h5>
                        <span className="item-details">
                          IT-Core / 2017 - 2018
                        </span>
                        <p className="item-description">
                          At this event a group of 3 – 5 members should build a
                          working IOT hack/prototype within the time-frame of 3
                          months. The prototypes were judged by expert panel on
                          technical aspects/complexity, user-friendliness,
                          features and commercial or social value. I
                          participated with 3 other team members for this event.
                          All members had their specific role. With some
                          sleepless nights and headaches we ended up taking the
                          second place by building a pest control with a
                          monitoring system and automatic bug detection.
                        </p>
                      </div>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <h5 className="item-title">Junior Data Engineer</h5>
                        <span className="item-details">
                          Staatsziekenfonds Suriname / Mar 2017 - Mar 2018
                        </span>
                        <p className="item-description">
                          As Data Engineer at SZF I was responsible for
                          developing, constructing, testing and maintaining
                          architectures such as databases and large-scale data
                          processing systems. Once continuous pipelines were
                          installed to – and from – these huge “pools” of
                          filtered information, data scientists can pull
                          relevant data sets for their analyses.
                        </p>
                      </div>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <h5 className="item-title">
                          Technical Application Administrator
                        </h5>
                        <span className="item-details">
                          Staatsziekenfonds Suriname / Oct 2015 - Feb 2017
                        </span>
                        <p className="item-description">
                          As Application Administrator at SZF I was responsible
                          for installing, configuring and managing of all the
                          small and big applications. I also build an API for
                          their mobile application where our clients could see
                          their insurance information.
                        </p>
                      </div>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <h5 className="item-title">
                          Co-founder & Web Developer
                        </h5>
                        <span className="item-details">
                          LAZRLAB / Oct 2013 - May 2015
                        </span>
                        <p className="item-description">
                          I was one of the co-founders of LAZRLAB. My
                          responsibilities at LAZRLAB was making website
                          designs, making data models, websites maintenance and
                          developing web applications.
                        </p>
                      </div>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <h5 className="item-title">
                          CANTO/Ericsson 3rd Annual i-Create e-Content
                          Competition
                        </h5>
                        <span className="item-details">
                          CANTO / 2012 - 2013
                        </span>
                        <p className="item-description">
                          In the year 2013 a friend and I won the first place in
                          the CANTO/Ericsson’s third i-Create e-Content Mobile
                          Apps Competition. This annual competition rewards the
                          most creative and innovative mobile application (apps)
                          developers in the Caribbean. The competition was
                          staged in all CANTO member countries.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Skills section*/}
              <div className="skills-section single-section page-top-margin-medium">
                <div className="row">
                  <div className="col-12">
                    <div className="section-heading">
                      {/* <p className="section-description">My level of knowledge in some tools</p> */}
                      <h2 className="section-title">Professional Skills</h2>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12 col-md-6">
                    {renderListOfProfessionalSkills(professionalSkillsFirst)}
                  </div>
                  <div className="col-12 col-md-6">
                    {renderListOfProfessionalSkills(professionalSkillsSecond)}
                  </div>
                </div>
              </div>
              {/* Video section*/}
              {/* <div className="video-section single-section">
                                <div className="row align-items-center">
                                    <div className="col-12 col-lg-6 content-part">
                                        <h3 className="video-title">Take a tour of my office</h3>
                                        <p className="video-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia cum quasi assumenda culpa praesentium consectetur voluptatibus expedita. Voluptatem tempore, aspernatur rem facilis, distinctio nemo! Odio velit, nemo dolorem voluptas!</p>
                                        <p className="video-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laudantium qui aspernatur unde mollitia, in laborum.</p>
                                    </div>
                                    <div className="col-12 col-lg-6 video-part"><a className="media-lightbox" href="https://www.youtube.com/watch?v=doteMqP6eSc" data-lightbox>
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <div className="embed-responsive-item element-cover-bg"><span className="play-wrapper"><i className="icon ion-md-play" /></span></div>
                                        </div>
                                    </a></div>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Resume;
