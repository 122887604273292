// 👇️ import the pdf document
import cvSiadisPdf from "./files/cv_siadis.pdf";

const persnalSkills = [
  {
    id: 1,
    name: "Teamwork",
  },
  {
    id: 2,
    name: "Creativity",
  },
  {
    id: 3,
    name: "Research",
  },
  {
    id: 4,
    name: "Analyze",
  },
  {
    id: 5,
    name: "Eager to learn",
  },
  {
    id: 6,
    name: "Motivation",
  },
  {
    id: 7,
    name: "Flexibility",
  },
  {
    id: 8,
    name: "Initiative",
  },
  {
    id: 9,
    name: "Problem-solving",
  },
  {
    id: 10,
    name: "Critical thinking",
  },
];

const renderListOfPersonalSkills = (skills) => {
  return skills.map((item) => (
    <div id={item.id} className="single-skill">
      <div className="skill-info">
        <span className="skill-name">{item.name}</span>
        <span style={{ color: "#009e66", fontSize: "20px" }}>
          <i className="icon ion-md-checkmark-circle-outline" />
        </span>
      </div>
    </div>
  ));
};

const About = () => {
  return (
    <>
      <div className="container page-top-margin-medium page-bottom-margin">
        <div className="row">
          <div className="col-12">
            <div>
              <div className="row">
                <div className="col-12">
                  <div className="section-heading page-heading text-center">
                    {/* <p className="section-description">Get to know me</p> */}
                    <h2 className="section-title p-2">Who am i?</h2>
                    <div className="animated-bar" />
                  </div>
                </div>
              </div>
              {/* Info section*/}
              <div className="info-section single-section mt-3">
                <div className="row align-items-center">
                  {/* Picture part*/}
                  <div className="col-12 col-lg-5 info-img">
                    <img
                      className="img-fluid img-thumbnail"
                      src="img/profile-info.jpg"
                      alt="About Picture"
                    />
                  </div>
                  {/* Content part*/}
                  <div className="col-12 col-lg-7 info-content">
                    <div className="content-block">
                      {/* <h2 className="content-subtitle">Who am i?</h2> */}
                      <h6 className="content-title">I'm Clint Siadis,</h6>
                      <div className="content-description">
                        <p>
                          a passionate software developer with over 6 years of
                          experience. I would describe myself as an enthusiastic
                          young man that is always eager to gain new knowledge
                          and skills within various areas. Currently I'm skilled
                          in Javascript, Python, PHP and SQL. Some of my
                          interest lies in following the latest news on new
                          technologies, watching movies or series, learning new
                          coding languages, playing football or basketball and
                          finishing Jigsaw puzzles. Most of my interests keeps
                          me fit and calm in mind and body.
                        </p>
                        <p>
                          One important thing I learned in software development
                          is that an application is not just an app, but a
                          solution to make our lives better.
                        </p>
                      </div>
                      <address className="content-info">
                        <div className="row">
                          <div className="col-12 col-md-6 single-info">
                            <span>Name:</span>
                            <p>Clint Siadis</p>
                          </div>
                          <div className="col-12 col-md-6 single-info">
                            <span>Email:</span>
                            <p>
                              <a href="mailto:emma@example.com">
                                csiadis@gmail.com
                              </a>
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 single-info">
                            <span>Born:</span>
                            <p>Paramaribo, SR</p>
                          </div>
                          <div className="col-12 col-md-6 single-info">
                            <span>Living in:</span>
                            <p>Utrecht, NL</p>
                          </div>
                        </div>
                      </address>
                      <div className="d-block d-sm-flex align-items-center">
                        <a
                          className="btn content-download button-main button-scheme"
                          href={cvSiadisPdf}
                          target="_blank"
                          rel="noreferrer"
                          role="button"
                        >
                          Download CV
                        </a>
                        <ul className="list-unstyled list-inline content-follow">
                          {/* <li className="list-inline-item"><a href="#0"><i className="icon ion-logo-twitter" /></a></li>
                                                    <li className="list-inline-item"><a href="#0"><i className="icon ion-logo-instagram" /></a></li> */}
                          {/* <li className="list-inline-item"><a href="#0"><i className="icon ion-logo-facebook" /></a></li> */}
                          <li className="list-inline-item">
                            <a
                              href="https://www.linkedin.com/in/clint-siadis-1413a473/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="icon ion-logo-linkedin" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a
                              href="https://github.com/clint1302"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="icon ion-logo-github" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Services section*/}
              <div className="services-section single-section">
                <div className="row">
                  <div className="col-12">
                    <div className="section-heading">
                      <h2 className="section-title">My Awards so far...</h2>
                      {/* <p className="section-description">so far...</p> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* Single service*/}
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="single-service">
                      <i className="icon service-icon ion-md-trophy" />
                      <a
                        href="https://www.facebook.com/hackomation/?eid=ARBddddzT9o4r9A7FafgOgPkGnNx7oYT8SMfnzaT-t8xdZG8BQKiiQJ_FZs0ckrBqiOd94_UoAnlgRrI&hc_ref=ARR_z0rJD4U9e3nbmqTCOdM-b7CqBajD4tePh2B-p4-UQ1nS1vW508TZgWddFuWO3RU&__xts__[0]=68.ARAluXQT5SxSjVAXtHRLbdGW1DF-gQJTeEd35SWGOjV"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <h6 className="service-title">
                          HACKOMATION INTERNET OF THINGS PROTOTYPING CHALLENGE
                        </h6>
                      </a>
                      <p className="service-description">
                        I participated with 3 other team members for this event.
                        All members had their specific role. With some sleepless
                        nights and headaches we ended up taking the second place
                        by building a pest control with a monitoring system and
                        automatic bug detection.
                      </p>
                    </div>
                  </div>
                  {/* Single service*/}
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="single-service">
                      <i className="icon service-icon ion-md-trophy" />
                      <a
                        href="https://www.canto.org/blog/team-ideals-of-suriname-captures-first-place-in-the-cantoericsson-3rd-annual-i-create-e-content-competition/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <h6 className="service-title">
                          CANTO/Ericsson 3rd Annual i-Create e-Content
                          Competition
                        </h6>
                      </a>
                      <p className="service-description">
                        In the year 2013 me and a friend won the first place in
                        the CANTO/Ericsson’s third i-Create e-Content Mobile
                        Apps Competition. And that was the start of a all my
                        tech adventures.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="resume-section single-section">
                <div className="row">
                  <div className="col-12 col-md-8">
                    <div className="col-block education">
                      <div className="section-heading">
                        <h2 className="section-title">Education</h2>
                      </div>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <a href="#" target="_blank" rel="noreferrer">
                          <h5 className="item-title">Bachelor of Science</h5>
                        </a>
                        <span className="item-details">
                          Hogeschool Utrecht / 2019 - 2024
                        </span>
                        <p className="item-description">
                          In 2019 they started with a new program: Open-ICT.
                          Open-ICT gives you a lot of freedom to decide what you
                          want to learn. However, you must be able to handle
                          this freedom. Open-ICT therefore fits particularly
                          well with me because I like to study independently,
                          want to work together with others, have a high
                          ambition and self-discipline.
                        </p>
                      </div>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <a href="#" target="_blank" rel="noreferrer">
                          <h5 className="item-title">Software Developer</h5>
                        </a>
                        <span className="item-details">
                          Natin - MBO / 2010 - 2014
                        </span>
                        <p className="item-description">
                          At NATIN-MBO I learned the fundamentals of
                          programming, designing systems using the Unified
                          Modeling Language, creating databases and developing
                          in C++ and Java.
                        </p>
                      </div>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <a href="#" target="_blank" rel="noreferrer">
                          <h5 className="item-title">A+ PC technician</h5>
                        </a>
                        <span className="item-details">
                          Hogeschool Utrecht / 2011 - 2012
                        </span>
                        <p className="item-description">
                          This was a training of six months where I learned to
                          install and configure operating systems, assemble
                          hardware of a computer and laptop, troubleshoot and
                          fix software and hardware problems.
                        </p>
                      </div>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <a href="#" target="_blank" rel="noreferrer">
                          <h5 className="item-title">Electrical Engineering</h5>
                        </a>
                        <span className="item-details">
                          Suriname Technical School 2 / 2006 - 2010
                        </span>
                        <p className="item-description">
                          STS stands for Suriname Technical School, a school
                          where I learned the basics of electrical engineering.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    {/* Skills section*/}
                    <div className="skills-section single-section">
                      <div className="row">
                        <div className="col-12">
                          <div className="section-heading">
                            <h2 className="section-title">Personal skills</h2>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-12">
                          {renderListOfPersonalSkills(persnalSkills)}
                          {/* <div className="single-skill" data-percentage={80}>
                                                        <div className="skill-info"><span className="skill-name">Creativity</span><span className="skill-percentage" /></div>
                                                        <div className="progress skill-progress">
                                                            <div className="progress-bar" role="progressbar" aria-valuemin={0} aria-valuemax={100} />
                                                        </div>
                                                    </div>
                                                    <div className="single-skill" data-percentage={90}>
                                                        <div className="skill-info"><span className="skill-name">Research</span><span className="skill-percentage" /></div>
                                                        <div className="progress skill-progress">
                                                            <div className="progress-bar" role="progressbar" aria-valuemin={0} aria-valuemax={100} />
                                                        </div>
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
